//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";


export default {
    name: "BusinessCompany",
    components: { DatePicker },
    data() {
        return {
            //dateFormat: 'DD MMMM YYYY',
            isCheck: false,
            isLoading: true,
            ar: ar,
            en: en,
            lang: localStorage.lang,
            total_pages: 0,
            total_rows: 0,
            businessCompanyList: [],
            businessCompanyPushList: [],
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: 0,
            },
            Filter: {},
        };
    },

    mounted() {
        //let self = this;
        //self.load();


        this.isLoading = false
    },
    methods: {
        load() {
            let self = this;
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "CompanyManagement/PublicGetAll", self.query)
                .then(function (res) {
                    self.businessCompanyPushList = res.data.Data;
                    self.businessCompanyList.push(...self.businessCompanyPushList);
                    self.query.PageNumber = res.data.PageNumber;
                    self.query.PageSize = res.data.PageSize;
                    self.total_rows = res.data.Total;
                    self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                    if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                        setTimeout(function () { }, 100);
                    }
                    self.isCheck = false;
                    if (self.total_pages <= self.query.PageNumber) self.isCheck = true;
                }).finally(() => self.isLoading = false);
        },

    },
    created() { },
    updated() { },
};
